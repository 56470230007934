import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Skeleton } from "primereact/skeleton";
import { Controller } from "react-hook-form";
import HeaderEditor from "shared/components/header-editor/HeaderEditor"

export const GeneralData = ({isLoading, control, dataMaster, valuesFields}) => {
  const validateLength = (value) => {
    if (typeof value === 'string') {
      return value.replace(/<[^>]+>/g, '')?.length;
    } else if (value?.htmlValue) {
      return value.htmlValue.replace(/<[^>]+>/g, '').length;
    }
    return '0';
  };
  
  return (
    <Panel header="DATOS GENERALES" toggleable className="col-span-10 lg:col-span-10">
      {isLoading ? (
        <Skeleton height="26rem" />
      ) : (
        <>
          <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2">
            <span className="p-float-label w-full mt-2">
              <Controller
                control={control}
                rules={{
                  required: "El Nombre de la Ficha es requerido.",
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputText
                    name="code"
                    onChange={(e) => {
                      if (e.target.value.length > 20) return;
                      onChange(e);
                    }}
                    value={value || ""}
                    className={error ? "p-invalid w-full" : "w-full"}
                  />
                )}
                name="code"
              />
              <label>Código *</label>
            </span>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-2 mt-7">
            <span className="p-float-label w-full">
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputText
                    name="name"
                    onChange={onChange}
                    value={value || ""}
                    className={error ? "p-invalid w-full" : "w-full"}
                  />
                )}
                name="name"
                rules={{
                  required: "El Nombre de la Ficha es requerido.",
                }}
              />
              <label>Nombre de la Ficha *</label>
            </span>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 mt-5">
            <span className="p-float-label w-full mt-2">
              <Controller
                control={control}
                rules={{
                  required: "El Tipo de la Ficha es requerido.",
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Dropdown
                    options={dataMaster?.businessSheetType || []}
                    name="typeId"
                    optionLabel="name"
                    optionValue="id"
                    onChange={onChange}
                    value={value}
                    className={error ? "p-invalid w-full" : "w-full"}
                  />
                )}
                name="typeId"
              />
              <label>Tipo *</label>
            </span>
          </div>
          <div className="mt-5">
            <Controller
              control={control}
              // rules={{required: true}}
              render={({ field: { onChange, value } }) => (
                <>
                  <Editor
                    headerTemplate={HeaderEditor()}
                    onTextChange={(e) => onChange(e.htmlValue)}
                    value={value}
                    // className={`${errors.processDescription?.type ==='required' ? 'p-invalid': ''}`}
                    placeholder="Añade la descripción del proceso"
                    name="description"
                  />
                  <p className="font-semibold text-base text-gray-500 lg:mt-1 mt-3 text-right">
                    Caracteres {validateLength(valuesFields.description)} / 4000{" "}
                  </p>
                </>
              )}
              name="description"
            />
          </div>
          <div className="grid  sm:grid-cols-1 md:grid-cols-1 gap-2">
            <span className="p-float-label w-full mt-2">
              <Controller
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputText
                    name="link"
                    onChange={onChange}
                    value={value || ""}
                    disabled
                    className={error ? "p-invalid w-full disabled" : "w-full disabled"}
                  />
                )}
                name="link"
              />
              <label>Enlace</label>
            </span>
          </div>
        </>
      )}
    </Panel>
  );
};
