import { CoreConstants } from 'core/commons/CoreConstants';
import { ReportConstants } from 'features/customers/commons/ReportConstants';
import { toastAction } from 'core/store/actions/ToastAction';
import { BusinessServices } from 'features/customers/services/BusinessService';
import { ReportService } from 'features/customers/services/ReportService';
import FileSaver from "file-saver";

const setReports = (payload) => ({
  type: ReportConstants.Action.Report.SEARCH_REPORT,
  payload,
});

const searchReport = (pagination, setRowCount) => {
  return (dispatch) => {
    dispatch(setReports({ loading: true }));
    ReportService.search({ fields: pagination })
      .then(({ data }) => {
        const status = data?.status ?? '';
        if (status === CoreConstants.HttpResponse.OK) {
          const { results, ...rest } = data.data;
          setRowCount(rest.rowCount)
          dispatch(setReports({ data: results }));
          return;
        } else if (status === CoreConstants.HttpResponse.ERROR_FUNTIONAL){
          dispatch(toastAction.warn("Proyectos", data.message));
        }else{
          dispatch(toastAction.error("Proyectos", data.message));
        }

      })
      .catch((error)=>{
      })
      .finally(() => {
        dispatch(setReports({ loading: false }));
      });
  };
};

const downloadReport = (searchFields) => {
  return (dispatch) => {       
    dispatch(setReports({ isLoadingDownload: true }));
    ReportService.generateReport(searchFields)
      .then(({ data }) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
        });
        FileSaver.saveAs(blob, "Proyectos.xlsx");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setReports({ isLoadingDownload: false }));
      });
  };
};

const setDataMaster = (payload) => ({
  type: ReportConstants.Action.Report.DATA_MASTER,
  payload,
})

const getDataMaster = () => {
  return (dispatch) => {
    dispatch(setDataMaster({ isLoadingDataMaster: true }));
    BusinessServices.getDataMaster()
      .then(({ data }) => {
        if (data.status === CoreConstants.HttpResponse.OK) {
          dispatch(setDataMaster({ data: data.data }));
        }
      })
      .catch(console.error)
      .finally(() => {
        dispatch(setDataMaster({ isLoadingDataMaster: false }));
      });
  };
};

export const ReportAction = {
  searchReport,
  getDataMaster,
  downloadReport,
}
