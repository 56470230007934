import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FilterPaginationAction } from "shared/store/actions/FilterPaginationAction"

export const usePagination = (getPinersValues) => {
  const { currentPage, pageSize, rowCount  } = useSelector(state => state.filterPaginationReducer.advancedFilter.pagination)
  const { order, columnOrder, search, showModal, values: piners, download } = useSelector(state => state.filterPaginationReducer.advancedFilter)

  const [values, setValues] = useState({})

  const first = (currentPage - 1) * pageSize
  const dispatch = useDispatch()

  const onPageChange = ({ first, rows, page, pageCount }) => {
    dispatch(FilterPaginationAction.changePage({ currentPage: page + 1, pageSize: rows }));
  };

  const setRowCount = (rowCount) => {
    dispatch(FilterPaginationAction.changePage({ rowCount }))
  }

  const onSort = ({ sortOrder, sortField }) => {
    const order = sortOrder + '' === '1' ? 'asc' : 'desc';
    const columnOrder = sortField
    dispatch(FilterPaginationAction.changeSort({ order, columnOrder }));
  }

  const changeShowModal = ( showModal ) => {
    dispatch(FilterPaginationAction.changeSort({ showModal }));
  }

  const onChangeValues = ({ name, value }) => {
    setValues( prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const resetValues = () => {
    setValues({})
  }

  const removePiner = (field) => {
    const newPiners = piners.filter( piner => piner.field !== field)
    setValues( prev => ({
      ...prev,
      [field]: null,
    }))
    if(currentPage !== 1){
      dispatch(FilterPaginationAction.changeSort({ values: newPiners, search: false }));
      dispatch(FilterPaginationAction.changePage({ currentPage: 1 }));
    } else {
      dispatch(FilterPaginationAction.changeSort({ values: newPiners, search: true }));
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const piners = getPinersValues(values)
    if(currentPage !== 1){
      dispatch(FilterPaginationAction.changeSort({ showModal: false, values: piners, search: false }));
      dispatch(FilterPaginationAction.changePage({ currentPage: 1 }));
    } else {
      dispatch(FilterPaginationAction.changeSort({ showModal: false, values: piners, search: true }));
    }
  }

  const onDownload = (e) => {
    e.preventDefault()
    const piners = getPinersValues(values)
    dispatch(FilterPaginationAction.changeSort({ values: piners, download: true }));
  }

  const cancelSearch = () => {
    dispatch(FilterPaginationAction.changeSort({ search: false }));
  }
  
  const searchData = () => {
    dispatch(FilterPaginationAction.changeSort({ search: true }));
  }

  const cancelDownload = () => {
    dispatch(FilterPaginationAction.changeSort({ download: false }));
  }

  const resetState = () => {
    dispatch(FilterPaginationAction.resetState());
  }
  
  return { 
    pageSize, 
    first,
    currentPage,
    rowCount,
    order,
    orderTable: order === 'asc' ? 1 : -1,
    columnOrder,
    onPageChange, 
    setRowCount,
    onSort,
    onChangeValues,
    values,
    search,
    onSubmit,
    changeShowModal,
    showModal,
    piners,
    removePiner,
    cancelSearch,
    download,
    onDownload,
    cancelDownload,
    resetValues,
    resetState,
    searchData,
  }
}