

import React, {Fragment, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { useSelector, useDispatch } from 'react-redux';
import { CoreConstants } from 'core/commons/CoreConstants';
import { SurveyAction } from 'features/survey/store/actions/SurveyAction';
import { useHistory } from 'react-router-dom';
import './TableComponent.scss'
import { useClaimSurvey } from 'features/survey/hooks/useClaimSurvey';
import { SurveyModalAnswers } from './SurveyModalAnswers';
import { SurveysConstants } from 'features/survey/commons/SurveyConstants';

const TableComponent = ({selectedNode, setSelectedNode, surveySelected, setSurveySelected, setDisplayModalAction, setActionSelected}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isDeleted, isUpdated } = useSelector((state) => state.surveyReducer.survey);
    const { isLoading , data, isLoaded } = useSelector(state => state.surveyReducer.surveys)

    const getCreationDate = (rowData) => {
        if(rowData.modificationDate !== null) {
            return  moment(rowData.modificationDate).format(CoreConstants.Format.LOCALE); 
        }else {
            return "";
        }
    }
    const typeViews = [
        { icon: 'pi pi-list', value: CoreConstants.TypeView.LIST },
        { icon: 'pi pi-microsoft', value: CoreConstants.TypeView.CANVA }
    ];
    const columns = [
        // { field: 'title', header: 'Título' },
        { field: 'fullName', header: 'Propietario'},
        { field: 'questionsCount', header: 'Preguntas'},
        { field: 'answersCount', header: 'Respuestas'},
        { field: 'statusName', header: 'Estado'},
        { field: 'modificationDate', header: 'Ultima Modificación', body: getCreationDate }
    ];

    const {isShare, isAnalyze} = useClaimSurvey();

    const [selectedColumns, setSelectedColumns] = useState(columns);
    const [typeView, setTypeView] = useState(CoreConstants.TypeView.LIST);
    const actionsHeader = useRef(null);
    const menu = useRef(null);
    const dragItem = useRef();
    const dragOverItem = useRef();
    const [showConfirmDialog, setshowConfirmDialog] = useState(false);
    const [showConfirmClose, setShowConfirmClose] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);
    const [isFirstLoading, setFirstLoading] = useState(true); 
    const [displayModal, setDisplayModal] = useState(false)
    const [surveyModalSelected, setSurveyModalSelected] = useState(null)

    useEffect(() => {
        if (isFirstLoading) {
            tryParseFilter();
            setFirstLoading(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     if (selectedNode) {
    //         if (selectedNode === 'Recientes' || selectedNode === 'Vigentes' || selectedNode === 'Mis Documentos' || selectedNode === 'Papelera') return
    
    //     }
    // }, [selectedNode])

    // useEffect(() => {
    //     dispatch(SurveyAction.search());
    // }, [])
    
    // useEffect(() => {
    //     if (isDeleted) {
    //         setshowConfirmDialog(false);
    //         dispatch(SurveyAction.resetAfterSaved());
    //         dispatch(SurveyAction.search());
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isDeleted])

    useEffect(() => {
        if (isUpdated) {
            dispatch(SurveyAction.resetAfterSaved());
            // dispatch(SurveyAction.getDataListFolders());
            dispatch(SurveyAction.search());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdated])

    useEffect(() => {
        if (selectedRow) {
            setSelectedRow(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow])

    const titleDocuments = (pathTitle, type) => {
        if (!pathTitle) return
        // Para las carpetas
        const pathSplit = pathTitle.split('/')
        if (type === 'F') {
            const folderName = pathSplit[pathSplit.length-2]
            return folderName
        }
        // Para los archivos
        return pathTitle
    }

    const answersCountTemplate = (rowData) => {
        return (
            <p 
                className="underline text-blue-700 inline"
                onClick={() => {
                    setDisplayModal(true)
                    setSurveyModalSelected(rowData)
                }}
            >{rowData.answersCount}</p>
        )
    }

    const columnComponents = selectedColumns.map(col => {
        if (col.field === 'modificationDate')
            return <Column key={col.field} field={col.field} header={col.header} body={getCreationDate} sortable />;
        if (col.field === 'answersCount')
            return <Column 
                key={col.field} 
                field={col.field} 
                header={col.header} 
                body={answersCountTemplate} 
            />;

        if (col.body)
            return <Column key={col.field} field={col.field} header={col.header} body={col.body} sortable />;

        return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

    const actionHeaderTemplate = (rowData) => {
        return <i
            key="action"
            className="pi pi-ellipsis-v cursor-pointer"
            onClick={(e) => actionsHeader.current.toggle(e)}></i>;
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <i
                key={rowData.id}
                className="pi pi-ellipsis-v cursor-pointer"
                onClick={(event) => {
                    setSurveySelected(rowData)
                    menu.current.toggle(event)
                    }}
            ></i>
        )
    }

    const tryParseFilter = () => {
        try {
            const filters = JSON.parse(localStorage.getItem("survey-filter"));
            if (filters)
                setSelectedColumns(filters);
            else
                setSelectedColumns(columns);
        } catch (error) {
            localStorage.removeItem('survey-filter');
        }
    }

    const onColumnToggle = (e, item) => {
        let _selectedColumns = [...selectedColumns];

        if (e.checked)
            _selectedColumns.push(item);
        else
            _selectedColumns = _selectedColumns.filter(c => { return c.field !== e.value });

        setSelectedColumns(_selectedColumns);
        localStorage.setItem("survey-filter", JSON.stringify(_selectedColumns));
        
    }

    const getColumns = () => {
        let array = [];
        columns.map((item) => {
            const column = <div key={item.field} className="p-field-checkbox py-1">
                <Checkbox
                    inputId={item.field}
                    name="column"
                    value={item.field}
                    onChange={(e) => onColumnToggle(e, item)}
                    checked={selectedColumns.some((x) => x.field === item.field)}
                />
                <label className="ml-1" htmlFor={item.field}>{item.header}</label>
            </div>
            return array.push(column);
        });
        
        return array
    };

    // const onRowSelect = (data) => {
    //     if (data.field === 'name' || data.field === 'owner'  || data.field === 'latestChangeDate'){
    //         const name = data.rowData.name.split('/')
    //         name.pop()
    //         const path = name.join('/')
    //         if (data.rowData.itemType === 'F') {
    //             setSelectedNode(path)
    //             // setSelectedFolder(data.rowData)
    //             return
    //         }
    //         if (data.rowData.itemType === 'D') {
    //             // dispatch(SurveyAction.getDocumentById(data.rowData.id));
    //             setActionSelected('readOnly')
    //             // setDisplayModal(true)
    //             return
    //         }
    //     }
    // }

    // const onCanvaSelect = (data) => {
    //     const name = data.name.split('/')
    //     name.pop()
    //     const path = name.join('/')
    //     if (data.itemType === 'F') {
    //         setSelectedNode(path)
    //         // setSelectedFolder(data)
    //         return
    //     }
    //     if (data.itemType === 'D') {
    //         // dispatch(SurveyAction.getDocumentById(data.id));
    //         setActionSelected('readOnly')
    //         // setDisplayModal(true)
    //         return
    //     }
    // }

    let items = [
        {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (e) => {
                // dispatch(SurveyAction.getSurveyById(surveySelected.id));
                history.push('/encuestas/encuesta/' + surveySelected.id);
                // setDisplayModal(true)
            }
        },
        {
            label: 'Eliminar',
            icon: 'pi pi-times',
            command: (e) => {
                setshowConfirmDialog(true);
            }
        },
        {
            label: 'Copiar',
            icon: 'pi pi-copy',
            command: (e) => {
                dispatch(SurveyAction.getSurveyCopyById(surveySelected.id));
                history.push('/encuestas/encuesta/nuevo');
            }
        },
        {
            label: 'Analizar',
            icon: 'pi pi-eye',
            command: (e) => {
                history.push('/encuestas/encuesta/analisis/' + surveySelected.id);
            }
        },
        {
            label: 'Compartir',
            icon: 'pi pi-share-alt',
            command: (e) => {
                dispatch(SurveyAction.shareSurvey(surveySelected.id));
                setActionSelected('share')
                setDisplayModalAction(true)
            }
        },
        {
            label: 'Cerrar Encuesta',
            icon: 'pi pi-minus-circle',
            command: (e) => {
                // dispatch(SurveyAction.closeSurvey(surveySelected.id));
                setShowConfirmClose(true);
            }
        },
    ];

    if(!isShare){
        items = items.filter(el=> el.label!=='Compartir')
    }
    if(!isAnalyze){
        items = items.filter(el=> el.label!=='Analizar')
    }


    const itemsPublished = items.filter(item => item.label !== 'Eliminar');
    const itemsDraft = items.filter(item => item.label !== 'Cerrar Encuesta' && item.label !== 'Analizar')
    const itemsClosed = items.filter(item => item.label !== 'Cerrar Encuesta' && item.label !== 'Compartir')
  
    const confirmDelete = () => {
        dispatch(SurveyAction.deleteSurvey(surveySelected.id));
    }

    const cancelDelete = () => {
        setshowConfirmDialog(false);
    }

    const confirmClose = () => {
        dispatch(SurveyAction.closeSurvey(surveySelected.id));
    }

    const cancelClose = () => {
        setShowConfirmClose(false);
    }
    
    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
      };

    const drop = (e) => {
        if (dragItem.current.itemType === 'F') return
        if (dragOverItem.current) {
            const path = dragOverItem.current.name.split('/')
            path.pop()
            const targetPath = path.join('/')
            const fileMoved = {
                id: dragItem.current.id,
                sourceFileName: dragItem.current.name,
                targetPath
            }
            // dispatch(SurveyAction.moveDocument(fileMoved));
            dragItem.current = null;
            dragOverItem.current = null;
        }
    };

    const tituloDirectorio = (path) => {
        const pathSplit = path.split('/')
        const pathsList = pathSplit.map( (item, index) => {
            if (index > 0) {
                const pathSlice = pathSplit.slice(0, index + 1)
                const pathJoin = pathSlice.join('/')
                const newPath = {
                    label: `${item}`,
                    command: () => setSelectedNode(pathJoin)
                }
                return newPath
            }

            const newPath = {
                label: item,
                command: () => setSelectedNode(item)
            }
            return newPath
        })
        return pathsList
    }

    const home = { icon: 'pi pi-home', command: () => {
        setSelectedNode('')
        // setSelectedFolder(null)
    }}

    const bodyTemplate = () => <Skeleton></Skeleton>

    return (
        <Fragment>
            <div className="flex items-center justify-end px-2 mb-2">
                {/* <BreadCrumb model={tituloDirectorio(selectedNode)} className='bread-crumb text-lg' home={home} /> */}
                <SelectButton
                    unselectable={false}
                    value={typeView}
                    options={typeViews}
                    onChange={(e) => setTypeView(e.value)}
                    itemTemplate={(option) => { return <i className={option.icon}></i>; }}
                />
            </div>
            
            {typeView === CoreConstants.TypeView.CANVA && 
                <div 
                    className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-2"
                >
                    {
                        isLoading ? (<Skeleton className='mt-3' height="10.2rem" /> ) : 
                        (
                            // data.map((item, index) => (
                            data.map((item, index) => (
                                <div 
                                    draggable={item.itemType === 'F' ? false : true}
                                    onDragStart={(e) => dragStart(e, item)}
                                    onDragEnter={(e) => dragEnter(e, item)}
                                    onDragEnd={drop}
                                    key={"card" + index}
                                    data-pr-tooltip={titleDocuments(item.title, item.itemType)}
                                    className="flex flex-col max-w-sm gap-y-2
                                        rounded-lg border border-gray-200 hover:bg-gray-100 shadow-sm
                                        dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 document-card"
                                >
                                    {/* <div className='flex justify-center items-center w-full h-32 cursor-pointer' onClick={() => onCanvaSelect(item)}>
                                        {
                                            item.mimeType === 'application/pdf' || item.mimeType === 'image/jpeg'|| item.mimeType === 'image/jpg' || item.mimeType === 'image/png'   || item.mimeType === 'text/plain'
                                            ?  
                                            (
                                                <object data={item.url} type={item.mimeType} width="100%" height="100%" className='object-cover'>
                                                </object>
                                            )
                                            : (
                                                item.itemType === 'F' ?
                                                <div className="icon-carpeta"></div>
                                                : ( iconTypeOfFileCanva(item) )
                                            )
                                        }
                                    </div> */}
                                    <Tooltip target=".document-card" position='top'/>
                                    <div className="flex justify-between items-center  px-3 pb-2" >
                                        {/* <h5 key={"title" + item.id} className="mb-0 text-md font-medium truncate  text-gray-900 dark:text-white">{titleDocuments(item.title, item.itemType)}</h5> */}
                                        <h5 key={"title" + item.id} className="mb-0 text-md font-medium truncate  text-gray-900 dark:text-white">{item.title}</h5>
                                        <i
                                            key={"options" + item.id}
                                            className="pi pi-ellipsis-v cursor-pointer"
                                            onClick={(event) => {
                                                setSurveySelected(item);
                                                menu.current.toggle(event)
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            ))
                        )
                    }
                </div>
            }
            
            {typeView === CoreConstants.TypeView.LIST &&
                <div className="mt-3" style={{ maxWidth: (window.innerWidth - 100) + 'px' }}>          
                    {
                        (!isLoaded) ?(
                            <DataTable value={[1,2,3]} className='tableSurvey' size="small" responsiveLayout='scroll'>
                                <Column field="title" header="Título" body={bodyTemplate}></Column>
                                <Column field="fullName" header="Propietario" body={bodyTemplate}></Column>
                                <Column field="questionsCount" header="Preguntas" body={bodyTemplate}></Column>
                                <Column field="answersCount" header="Respuestas" body={bodyTemplate}></Column>
                                <Column field="status" header="Estado" body={bodyTemplate}></Column>
                                <Column field="modificationDate" header="Ultima Modificación" body={bodyTemplate}></Column>
                                <Column field="" header="" body={bodyTemplate}></Column>
                            </DataTable>
                            // <Skeleton height="14.2rem" />
                          ) : (
                            <DataTable
                                className='tableSurvey'
                                value={data}
                                responsiveLayout="scroll"
                                size="small"
                                emptyMessage="No se encontraron encuestas"
                                selectionMode="single"
                                cellSelection
                                dataKey="id"
                                paginator rows={10}
                                // selection={selectedRow} onSelectionChange={e => setSelectedRow(e.value)}
                                // onCellSelect={onRowSelect}
                                // sortField={sortField} sortOrder={sortOrder} onSort={(e) => onSort(e)}
                                >
                                <Column key='title' field='title' header='Título' sortable />
                                {columnComponents}
                                <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column>
                            </DataTable>
                          )
                    }          
                </div>
            }

            <OverlayPanel ref={actionsHeader} showCloseIcon style={{ width: '300px' }}>{getColumns()}</OverlayPanel>

            <Menu model={ surveySelected?.statusCode === SurveysConstants.SurveyStatus.PUBLISHED ? itemsPublished : surveySelected?.statusCode === SurveysConstants.SurveyStatus.DRAFT ? itemsDraft : surveySelected?.statusCode === SurveysConstants.SurveyStatus.CLOSED ? itemsClosed : items } popup ref={menu} id="popup_menu" />

            <ConfirmDialog
                visible={showConfirmDialog}
                onHide={cancelDelete}
                message="¿Está seguro que desea eliminar la encuesta?"
                header="Eliminar"
                icon="pi pi-trash"
                accept={confirmDelete}
                reject={cancelDelete}
            />
            <ConfirmDialog
                visible={showConfirmClose}
                onHide={cancelClose}
                message="¿Está seguro que desea cerrar la encuesta?"
                header="Cerrar Encuesta"
                icon="pi pi-minus-circle"
                accept={confirmClose}
                reject={cancelClose}
            />
            <SurveyModalAnswers 
                displayModal={displayModal} setDisplayModal={setDisplayModal} 
                surveySelected={surveyModalSelected} setSurveySelected={setSurveyModalSelected}
                // title={typeModal === 'seguimiento' ? 'Seguimiento del Indicador' : 'Gráficos del Indicador'}
            />
        </Fragment >
    );
};

export default TableComponent;
