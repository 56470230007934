import { apiService } from 'core/services/ApiService';
const currentModule = 'customer/api/Business/Report';
// const currentModule = 'customer/api/Business/Report';

const search = ({ fields = {} }) => {
  let initialFields = {
    page: 1,
    pageSize: 10,
    columnOrder: 'name',
    order: 'asc',
    ...fields,
  };

  return apiService.Post(`${currentModule}/Search`, initialFields);
};

const generateReport = (payload) => {
  return apiService.Post(`${currentModule}/GenerateReport`, payload, {
    responseType: 'blob',
  });
};

export const ReportService = {
  search,
  generateReport,
};
