import { CoreConstants } from 'core/commons/CoreConstants'
import { SurveyAction } from 'features/survey/store/actions/SurveyAction'
import moment from 'moment'
import { Column } from 'primereact/column'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Divider } from 'primereact/divider'
import { Paginator } from 'primereact/paginator'
import { Skeleton } from 'primereact/skeleton'
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const SurveyModalAnswers = ({displayModal, setDisplayModal, surveySelected, setSurveySelected}) => {

  const dispatch = useDispatch();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [answerSelected, setAnswerSelected] = useState(null)
  const advancedFilterSurveyAnswers = useSelector((state) => state.surveyReducer.advancedFilterSurveyAnswers);
  const { isLoading, data, isDeleted } = useSelector(state => state.surveyReducer.surveyAnswers)
  const user = useSelector(state => state.authReducer.user)
  // console.log(data)

  const isAdmin = user.roles?.some(role => role.codigo === CoreConstants.Role.ADMIN)
  const isOwner = user?.id === surveySelected?.ownerId

  useEffect(() => {
      if (surveySelected) {
          // setFirstLoading(false);
          setSortField("sentDate");
          setSortOrder(1);
          dispatch(SurveyAction.getSurveyAnswerPerRecipient(surveySelected.id));
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveySelected])

  useEffect(() => {
      if (surveySelected && isDeleted) {
          dispatch(SurveyAction.getSurveyAnswerPerRecipient(surveySelected.id));
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveySelected, isDeleted])
  
  // encuestas/encuesta/54/respuestas/28
  const columns = [
    { field: 'name', header: 'Nombre'},
    { field: 'email', header: 'Correo Electrónico'},
    { field: 'sentDate', header: 'Fecha de Envío' },
    { field: 'andsweredDate', header: 'Fecha de Respuesta' }
  ];

  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState(null);
  const [rows, setRows] = useState(10)
  const history = useHistory();

  const onSortCustom = (field) => {
    // console.log(field)
    // console.log(sortOrder)
    sortField === field ? setSortOrder(sortOrder * -1) : setSortField(field)
    // setSortField(field);
    // setSortOrder((prev) => (prev === 1 ? -1 : 1));
    
    advancedFilterSurveyAnswers.columnOrder = field;
    advancedFilterSurveyAnswers.order = (sortOrder * (sortField === field ? -1 : 1)) === 1 ? 'asc' : 'desc';
    // advancedFilterSurveyAnswers.order = sortOrder === 1 ? 'asc' : 'desc';
    advancedFilterSurveyAnswers.page = 1;
    dispatch(SurveyAction.updateFilterSurveyAnswers(advancedFilterSurveyAnswers));
    dispatch(SurveyAction.getSurveyAnswerPerRecipient(surveySelected?.id));
  }

  const onPageChange = (e) => {
    setRows(e.rows);
    advancedFilterSurveyAnswers.page = e.page + 1;
    advancedFilterSurveyAnswers.pageSize = e.rows;
    dispatch(SurveyAction.updateFilterSurveyAnswers(advancedFilterSurveyAnswers));
    dispatch(SurveyAction.getSurveyAnswerPerRecipient(surveySelected?.id));
  }

  const onSort = (e) => {
    setSortField(e.sortField);
    // setSortOrder(e.sortOrder);
    setSortOrder((prev) => (prev === 1 ? -1 : 1));

    advancedFilterSurveyAnswers.columnOrder = e.sortField;
    advancedFilterSurveyAnswers.order = e.sortOrder === 1 ? 'asc' : 'desc';
    // advancedFilterSurveyAnswers.order = sortOrder === 1 ? 'asc' : 'desc';
    advancedFilterSurveyAnswers.page = 1;
    dispatch(SurveyAction.updateFilterSurveyAnswers(advancedFilterSurveyAnswers));
    dispatch(SurveyAction.getSurveyAnswerPerRecipient(surveySelected?.id));
  }

  const onHide = () => {
    setDisplayModal(false)
    setSurveySelected(null)
    setAnswerSelected(null)
  }

  const confirmDelete = () => {
    dispatch(SurveyAction.deleteSurveyRecipient(surveySelected.id, answerSelected.id));
  }

  const cancelDelete = () => {
    setAnswerSelected(null)
    setShowConfirmDialog(false);
  }

  const headerTemplateWithToogleSort = (rowData) => {
      return (
          <div 
              className="flex justify-between items-center gap-x-2 cursor-pointer" 
              onClick={(() => onSortCustom(rowData.field))}
          >
              <div>{rowData.header}</div>
              <div className="flex">
                  {
                      sortOrder === 1 && sortField === rowData.field ? <i className="pi pi-sort-amount-up-alt"></i> : <i className="pi pi-sort-amount-down-alt"></i>
                  }
              </div>
          </div>
      )
  }
  
  const getSentDate = (rowData) => rowData.sentDate ? moment(rowData.sentDate).format(CoreConstants.Format.LOCALE) : '-'; 
  const getAndsweredDate = (rowData) => rowData.andsweredDate ? moment(rowData.andsweredDate).format(CoreConstants.Format.LOCALE) : '-'; 

  const columnComponents = selectedColumns.map(col => {
    if (col.field === 'sentDate' ) 
        return <Column key={col.field} field={col.field} header={col.header} body={getSentDate} sortable />;
    if (col.field === 'andsweredDate') 
        return <Column key={col.field} field={col.field} header={col.header} body={getAndsweredDate} sortable />;
        
    return <Column 
            key={col.field} field={col.field} 
            header={() => headerTemplateWithToogleSort(col)} 
            headerClassName={col.field === sortField ? `p-sortable-column p-highlight` : ''}  
           />;
  });

  const actionBodyDeleteTemplate = (rowData) => {

    if (isAdmin || isOwner) return (
      <>
        <Tooltip target=".delete-survey-answer" />
        <i
          data-pr-tooltip="Eliminar Respuesta"
          data-pr-at="right+5 top"
          data-pr-my="left center-2"
          key={rowData.id}
          className="pi pi-trash cursor-pointer delete-survey-answer"
          onClick={() => {
            setAnswerSelected(rowData)
            setShowConfirmDialog(true);
          }}
        ></i>
      </>
    )

    return null
  }

  const actionBodyViewTemplate = (rowData) => {

    if (!rowData.andsweredDate) return null
    return (
      <>
        <Tooltip target=".view-survey-answer" />
        <i
          // data-pr-position="right"
          data-pr-tooltip="Ver Respuesta"
          data-pr-at="right+5 top"
          data-pr-my="left center-2"
          key={rowData.id}
          className={`pi pi-eye cursor-pointer view-survey-answer`}
          onClick={() => {
              // onHide()
              // history.push('/encuestas/encuesta/respuestas/' + rowData.id);
              // history.push('/encuestas/encuesta/respuestas/' + rowData.surveyId);
              const url = `/#/encuestas/encuesta/${rowData.surveyId}/respuestas/${rowData.id}`;
              window.open(url, '_blank');
              // history.push(`/encuestas/encuesta/${rowData.surveyId}/respuestas/${rowData.id}`);
          }}></i>
      </>
    )
  }

  const bodyTemplate = () => <Skeleton></Skeleton>

  return (
    <Dialog header={surveySelected?.title} visible={displayModal} className='w-11/12 max-h-4/5 md:w-3/4' onHide={onHide}>
      <Divider />
      {
        (isLoading) ? (
          <DataTable value={[1,2,3]}  size="small" responsiveLayout="scroll">
              <Column field="title" header="Nombre" body={bodyTemplate}></Column>
              <Column field="fullName" header="Correo Electrónico" body={bodyTemplate}></Column>
              <Column field="questionsCount" header="Fecha de Envío" body={bodyTemplate}></Column>
              <Column field="answersCount" header="Fecha de Respuesta" body={bodyTemplate}></Column>
              <Column field="" header="" body={bodyTemplate}></Column>
              <Column field="" header="" body={bodyTemplate}></Column>
          </DataTable>
        ) : (
          <DataTable
            value={data}
            stripedRows
            responsiveLayout="scroll"
            size="small"
            emptyMessage="No se encontraron respuestas"
            sortField={sortField}
            onSort={onSort}
          >
              {/* <Column key='title' field='title' header='Título' sortable />
              {columnComponents}
              <Column header={actionHeaderTemplate} body={actionBodyTemplate} ></Column> */}
              {columnComponents}
              <Column align='center' header={''} body={actionBodyDeleteTemplate} ></Column>
              <Column align='center' header={''} body={actionBodyViewTemplate} ></Column>
          </DataTable>
        )
      }

      <Paginator
          key={"pag-1"}
          className="paginator-custom mt-4"
          rows={rows} 
          first={(data?.currentPage - 1) * rows}
          rowsPerPageOptions={[10,20,30]}
          totalRecords={data?.rowCount}
          onPageChange={onPageChange}
      ></Paginator>

      <ConfirmDialog
          visible={showConfirmDialog}
          onHide={cancelDelete}
          message="¿Está seguro que desea eliminar la encuesta?"
          header="Eliminar"
          icon="pi pi-trash"
          accept={confirmDelete}
          reject={cancelDelete}
      />
    </Dialog>
  )
}
