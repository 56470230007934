export class ReportConstants {
  static Action = class {
    static Report = class {
      static get SEARCH_REPORT() {
        return '@REPORT.SEARCH-REPORT';
      }
      static get DATA_MASTER() {
        return '@REPORT.DATA-MASTER';
      }
      static get EDIT_REPORT() {
        return '@REPORT.EDIT-REPORT';
      }
      static get DOWNLOAD_REPORT_STATUS() {
        return '@REPORT.DOWNLOAD-REPORT-STATUS';
      }
    };
  };

  static FieldName = class {
    static Filter = class {
      static get CUSTOMER_NAME(){
        return 'customerName'
      }
      static get PIPELINE_ID(){
        return 'pipelineId'
      }
      static get STAGE_ID(){
        return 'stageId'
      }
      static get DOCUMENT_NUMBER(){
        return 'documentNo'
      }
      static get BUSINESS_NAME(){
        return 'businessName'
      }
      static get CONTACT_NAME(){
        return 'contactName'
      }
    }
  }
}
