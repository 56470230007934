import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { Paginator } from "primereact/paginator"

import { getFilterValuesFromPiners } from "features/customers/helpers/report/ReportHelpers"
import { ReportAction } from "features/customers/store/actions/ReportAction"
import { useTable } from "shared/hooks/useTable"
import { useSelector } from "react-redux"
import { Skeleton } from "primereact/skeleton"

export const TableComponent = () => {
  const { pageSize, onPageChange, first, rowCount, columnOrder, onSort, order } = useTable("customerName", getFilterValuesFromPiners, ReportAction.searchReport)
  const data = useSelector(state => state.reportReducer.report.data)
  const loading = useSelector(state => state.reportReducer.report.loading)

  const columns = [
    { field: 'customerName', header: 'Empresa' },
    { field: 'documentNo', header: 'Ruc' },
    { field: 'businessName', header: 'Negocio' },
    { field: 'contactName', header: 'Contacto' },
    { field: 'position', header: 'Cargo' },
    { field: 'contactEmailAddress', header: 'Correo' },
    { field: 'contactPhoneNo', header: 'Teléfono' },
    { field: 'stage', header: 'Estado' },
  ];
  
  const ColumnComponents = columns.map((col) => {
    return (
      <Column key={col.field} field={col.field} header={col.header} sortable />
    );
  });

  if(loading) return <Skeleton height="8rem"/>

  return(
    <>
      <DataTable
        value={data}
        emptyMessage="No se encontraron negocios"
        stripedRows
        rowHover
        size="small"
        responsiveLayout="scroll"
        onSort={onSort}
        sortField={columnOrder}
        sortOrder={order}
      >
        {ColumnComponents}
      </DataTable>

      <Paginator
        first={first}
        rows={pageSize}
        onPageChange={onPageChange}
        totalRecords={rowCount}
        className="paginator-custom mt-4"
        rowsPerPageOptions={[5, 10, 15]}
      />
    </>
  )
}