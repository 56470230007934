import { ReportConstants } from "features/customers/commons/ReportConstants";

export const getFilterValuesFromPiners = (piners) => {
  const filterValues = {}
  piners.forEach(piner => {
    if(piner.field === ReportConstants.FieldName.Filter.CUSTOMER_NAME)
      filterValues[ReportConstants.FieldName.Filter.CUSTOMER_NAME] = piner.formValue
    if(piner.field === ReportConstants.FieldName.Filter.PIPELINE_ID) 
      filterValues[ReportConstants.FieldName.Filter.PIPELINE_ID] = piner.formValue.id
    if(piner.field === ReportConstants.FieldName.Filter.STAGE_ID) 
      filterValues[ReportConstants.FieldName.Filter.STAGE_ID] = piner.formValue.id
    if(piner.field === ReportConstants.FieldName.Filter.DOCUMENT_NUMBER) 
      filterValues[ReportConstants.FieldName.Filter.DOCUMENT_NUMBER] = piner.formValue
    if(piner.field === ReportConstants.FieldName.Filter.BUSINESS_NAME) 
      filterValues[ReportConstants.FieldName.Filter.BUSINESS_NAME] = piner.formValue
    if(piner.field === ReportConstants.FieldName.Filter.CONTACT_NAME) 
      filterValues[ReportConstants.FieldName.Filter.CONTACT_NAME] = piner.formValue
  });

  return filterValues
}

export const getPinersValues = (values) => {
  const piners = []
  if(values.customerName) piners.push({ field: ReportConstants.FieldName.Filter.CUSTOMER_NAME, value: values.customerName, formValue: values.customerName })
  if(values.pipelineId) piners.push({ field: ReportConstants.FieldName.Filter.PIPELINE_ID, value: values.pipelineId.description, formValue: values.pipelineId})
  if(values.stageId) piners.push({ field: ReportConstants.FieldName.Filter.STAGE_ID, value: values.stageId.stageName, formValue: values.stageId })
  if(values.documentNo) piners.push({ field: ReportConstants.FieldName.Filter.DOCUMENT_NUMBER, value: values.documentNo, formValue: values.documentNo })
  if(values.businessName) piners.push({ field: ReportConstants.FieldName.Filter.BUSINESS_NAME, value: values.businessName, formValue: values.businessName })
  if(values.contactName) piners.push({ field: ReportConstants.FieldName.Filter.CONTACT_NAME, value: values.contactName, formValue: values.contactName })

  return piners
}