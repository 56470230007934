import { ReportConstants } from "features/customers/commons/ReportConstants";

const initialState = {
  report: {
    data: [],
    loading: false,
    order: 'asc',
    pagination: {},
    columnOrder: 'name',
    isLoadingDownload: false,
  },
  dataMaster: {
    data: {},
    isLoadingDataMaster: false,
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case ReportConstants.Action.Report.SEARCH_REPORT:
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload,
        },
      };
      
    case ReportConstants.Action.Report.DATA_MASTER:
      return {
        ...state,
        dataMaster: {
          ...state.dataMaster,
          ...action.payload,
        },
      };

    default:
      return state;
  }
} 