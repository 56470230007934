import { useSelector } from "react-redux";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";

import ModalFilterComponent from "shared/components/modal-filter/ModalFilterComponent";
import PinerComponent from "shared/components/Piner/PinerComponent";

import { getFilterValuesFromPiners, getPinersValues } from "features/customers/helpers/report/ReportHelpers"
import { ReportAction } from "features/customers/store/actions/ReportAction";
import { useFilter } from "shared/hooks/useFilter";
import { ReportConstants } from "features/customers/commons/ReportConstants";
import { useEffect } from "react";

export const FilterComponent = () => {
  const { changeShowModal, onChangeValues, onSubmit, piners, removePiner, showModal, values, onDownload, resetValues, resetState } = useFilter("customerName", getFilterValuesFromPiners, getPinersValues, ReportAction.searchReport, ReportAction.downloadReport)
  const { data } = useSelector(state => state.reportReducer.dataMaster)
  const loading = useSelector(state => state.reportReducer.report.loading)
  const isLoadingDownload = useSelector(state => state.reportReducer.report.isLoadingDownload)

  useEffect(() => {

    return () => {
      resetState()
    }
  },[])

  return (
    <Panel header="FILTRO POR" toggleable>
      <form className="filter-roles">
        <div className={'w-full visible'}>
          <span className="p-float-label p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              id="input-search"
              name={ReportConstants.FieldName.Filter.CUSTOMER_NAME}
              value={values[ReportConstants.FieldName.Filter.CUSTOMER_NAME] || ""}
              onChange={(e) => {
                onChangeValues({name: e.target.name, value: e.target.value})
              }}
            />
            <label htmlFor="input-search">Buscar por nombre de empresa</label>
          </span>
        </div>
        <div className="filter-users-action">
          <div className="flex gap-3 w-full">
            <Button
              icon="pi pi-search"
              type="submit"
              label="Buscar"                  
              className="sig-button sig-primary w-full"
              onClick={onSubmit}
              loading={loading || isLoadingDownload}
            />
            <Button
              icon="pi pi-download"
              type="button"
              label="Descargar"
              className="sig-button sig-success w-full"
              onClick={onDownload}
              loading={loading || isLoadingDownload}
            />
          </div>
          <div className="flex mt-3">
            <Button
              type="button"
              label="Búsqueda avanzada"
              className="sig-button  sig-secondary w-full"
              onClick={() => changeShowModal(true)}
              loading={loading || isLoadingDownload}
            />
          </div>
        </div>
      </form>
        {piners.length > 0 && (
          <div className="filter-piners mt-3">
            {piners.map(({ value, field }, index) => (
              <PinerComponent
                name={value}
                field={field}
                removePiner={removePiner}
                key={index}
              />
            ))}
          </div>
        )}
        {
          showModal && 
          <ModalFilterComponent
            closeModal={() => changeShowModal(false)}
            handleSubmit={onSubmit}
            clearFields={resetValues}
          >
            <div className="w-full sm:w-80">
              <div className="flex gap-4 pt-3">
                <div className="w-full">
                  <span className="p-float-label">
                    <Dropdown
                      options={data.pipelines}
                      optionLabel="description"
                      filter
                      filterBy="description"
                      showClear
                      className="w-full"
                      name={ReportConstants.FieldName.Filter.PIPELINE_ID}
                      value={values[ReportConstants.FieldName.Filter.PIPELINE_ID]}
                      onChange={(e) => {
                        onChangeValues({name: e.target.name, value: e.target.value})
                        onChangeValues({
                          name: ReportConstants.FieldName.Filter.STAGE_ID,
                          value: undefined
                        })
                      }}
                    />
                    <label htmlFor="desde">Pipeline</label>
                  </span>
                </div>

                <div className="w-full">
                  <span className="p-float-label">
                    <Dropdown
                      options={data.stages?.filter(stage => stage.pipelineId === values[ReportConstants.FieldName.Filter.PIPELINE_ID]?.id)}
                      optionLabel="stageName"
                      filter
                      filterBy="stageName"
                      showClear
                      className="w-full"
                      name={ReportConstants.FieldName.Filter.STAGE_ID}
                      value={values[ReportConstants.FieldName.Filter.STAGE_ID]}
                      onChange={(e) => {
                        onChangeValues({name: e.target.name, value: e.target.value})
                      }}
                    />
                    <label htmlFor="hasta">Estado</label>
                  </span>
                </div>
              </div>

              <div className="flex gap-4 pt-7">
                <div className="w-full">
                  <span className="p-float-label">
                    <InputText
                      name={ReportConstants.FieldName.Filter.DOCUMENT_NUMBER}
                      value={values[ReportConstants.FieldName.Filter.DOCUMENT_NUMBER] || ""}
                      onChange={(e) => {onChangeValues({name: e.target.name, value: e.target.value})}}
                    />
                    <label htmlFor="desde">RUC</label>
                  </span>
                </div>
              </div>

              <div className="pt-7 w-full">
                <span className="p-float-label w-full">
                  <InputText
                    className="w-full"
                    name={ReportConstants.FieldName.Filter.BUSINESS_NAME}
                    value={values[ReportConstants.FieldName.Filter.BUSINESS_NAME]  || ""}
                      onChange={(e) => {onChangeValues({name: e.target.name, value: e.target.value})}}
                  />
                  <label htmlFor="quantityActiveEmployees">Servicio</label>
                </span>
              </div>

              <div className="pt-7 w-full">
                <span className="p-float-label w-full">
                  <InputText
                    className="w-full"
                    name={ReportConstants.FieldName.Filter.CONTACT_NAME}
                    value={values[ReportConstants.FieldName.Filter.CONTACT_NAME] || ""}
                      onChange={(e) => {onChangeValues({name: e.target.name, value: e.target.value})}}
                  />
                  <label htmlFor="quantityActiveEmployees">Contacto</label>
                </span>
              </div>
            </div>
          </ModalFilterComponent>
        }
    </Panel>
  )
}